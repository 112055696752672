import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { logoutUser } from "../features/auth/services/authActions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { FaChevronDown } from "react-icons/fa"; // Importing Chevron icon
import { clearError } from "../features/auth/redux/authSlice";

const NavBar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuthenticated, user, isVerified } = useAuth();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isAuthenticated, user]);

  const handleLogout = () => {
    dispatch(clearError());
    dispatch(logoutUser(navigate));
    setIsOpen(false); // Close mobile menu
    setDropdownOpen(false); // Close dropdown menu
  };

  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  useEffect(() => {
    setDropdownOpen(false); // Ensure dropdown is closed on load
    setIsOpen(false); // Ensure mobile menu is closed on load
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownOpen]);

  return (
    <nav
      className={`w-full transition-colors duration-300 py-2 ${
        isScrolled ? "bg-white shadow-lg" : "bg-transparent"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 h-14 flex items-center">
        <div className="flex justify-between items-center w-full">
          <div className="flex-shrink-0">
            <Link to="/" className="text-xl font-bold text-purple-600">
              SurpriseMePlz
            </Link>
          </div>

          <div className="hidden md:flex space-x-4 items-center">
            {isAuthenticated ? (
              isVerified ? (
                <>
                  <Link
                    to="/search/results"
                    className="text-gray-800 hover:text-purple-600"
                    onClick={() => setDropdownOpen(false)}
                  >
                    Find someone's wishlist
                  </Link>
                  <div className="relative z-20" ref={dropdownRef}>
                    <button
                      className="text-gray-800 hover:text-purple-600 dropdown flex items-center space-x-1"
                      onClick={toggleDropdown}
                    >
                      <span>{user?.name}</span>
                      {/* Chevron icon with rotation based on dropdown state */}
                      <FaChevronDown
                        className={`transform transition-transform duration-200 ${
                          dropdownOpen ? "rotate-180" : "rotate-0"
                        }`}
                      />
                    </button>
                    {dropdownOpen && (
                      <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-lg z-30">
                        <Link
                          to={`/profile/${user?._id}`}
                          className="block px-4 py-2 text-gray-800 hover:bg-purple-100"
                          onClick={() => setDropdownOpen(false)}
                        >
                          My Wishlist
                        </Link>
                        <button
                          onClick={handleLogout}
                          className="block px-4 py-2 text-gray-800 hover:bg-purple-100 w-full text-left"
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <button
                  onClick={handleLogout}
                  className="text-gray-800 hover:text-purple-600"
                >
                  Logout
                </button>
              )
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-gray-800 hover:text-purple-600"
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="bg-purple-500 text-white px-4 py-2 rounded-full hover:bg-purple-600 transition-transform transform hover:scale-105"
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>

          <div className="md:hidden flex items-center">
            {isAuthenticated && isVerified ? (
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-gray-800 hover:text-purple-600"
              >
                <div className="space-y-1">
                  <div className="w-6 h-0.5 bg-gray-800"></div>
                  <div className="w-6 h-0.5 bg-gray-800"></div>
                  <div className="w-6 h-0.5 bg-gray-800"></div>
                </div>
              </button>
            ) : isAuthenticated && !isVerified ? (
              <button
                onClick={handleLogout}
                className="text-gray-800 hover:text-purple-600"
              >
                Logout
              </button>
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-gray-800 hover:text-purple-600 mr-4"
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="bg-purple-500 text-white px-4 py-2 rounded-full hover:bg-purple-600 transition-transform transform hover:scale-105"
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      {isOpen && isAuthenticated && isVerified && (
        <div className="md:hidden px-4 pt-4 pb-2 space-y-2 bg-gray-50 shadow-inner z-20">
          <Link
            to="/search/results"
            className="block text-gray-800 hover:text-purple-600 px-4 py-2"
            onClick={() => setIsOpen(false)}
          >
            Find someone's wishlist
          </Link>
          <hr className="border-t border-gray-300" />
          <Link
            to={`/profile/${user?._id}`}
            className="block text-gray-800 hover:text-purple-600 px-4 py-2"
            onClick={() => setIsOpen(false)}
          >
            My Wishlist
          </Link>
          <hr className="border-t border-gray-300" />
          <button
            onClick={handleLogout}
            className="block text-gray-800 hover:text-purple-600 w-full text-left px-4 py-2"
          >
            Logout
          </button>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
