import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchProfileById } from "../services/profileActions";
import { RootState, AppDispatch } from "../../../redux/store";
import ProfileHeader from "./ProfileHeader";
import WishlistSection from "../../wishlist/components/WishlistSection";
import FloatingAddButton from "../../../components/FloatingAddButton";
import WishlistItemModal from "../../wishlist/components/WishlistItemModal";
import WishlistItemForm from "../../wishlist/components/WishlistItemForm";
import { WishlistItem } from "../../../redux/types";
import { useOwnership } from "../../../hooks/useOwnership";
import { editWishlistItem } from "../../wishlist/services/wishlistActions";
import AddWishlistFlow from "../../wishlist/components/AddWishListFlow";
import SocialLinksModal from "./SocialLinksModal";
import ProfileLoadingSkeleton from "./ProfileLoadingSkeleton";

const ProfilePage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const dispatch = useDispatch<AppDispatch>();

  const {
    data: profile,
    loading,
    error,
  } = useSelector((state: RootState) => state.profile);

  const [selectedItem, setSelectedItem] = useState<WishlistItem | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isAddFlowOpen, setIsAddFlowOpen] = useState(false);
  const [isSocialLinksModalOpen, setIsSocialLinksModalOpen] = useState(false);
  const [socialLinksUpdated, setSocialLinksUpdated] = useState(false);

  const isOwner = useOwnership(userId);

  const openEditModal = (item: WishlistItem) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
    setIsViewModalOpen(false);
  };

  const openViewModal = (item: WishlistItem) => {
    setSelectedItem(item);
    setIsViewModalOpen(true);
    setIsEditModalOpen(false);
  };

  const closeEditModal = () => {
    setSelectedItem(null);
    setIsEditModalOpen(false);
  };

  const closeViewModal = () => {
    setSelectedItem(null);
    setIsViewModalOpen(false);
  };

  const closeAddFlow = () => {
    setIsAddFlowOpen(false);
  };

  const handleSaveItem = async (updatedItem: WishlistItem) => {
    if (updatedItem._id) {
      await dispatch(editWishlistItem(updatedItem._id, updatedItem));
    }
    closeEditModal();
  };

  useEffect(() => {
    if (userId) {
      //console.log("Fetching profile for userId:", userId);
      dispatch(fetchProfileById(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    //console.log("Checking if social links modal should open...");
    if (
      !loading &&
      isOwner &&
      profile &&
      !profile.socialLinks?.instagram &&
      !profile.socialLinks?.tiktok &&
      !profile.socialLinks?.telegram
    ) {
      //console.log("Opening social links modal due to missing links.");
      setIsSocialLinksModalOpen(true);
    }
  }, [loading, isOwner, profile]);

  // Use an effect to close the modal once social links are updated
  useEffect(() => {
    if (socialLinksUpdated) {
      //console.log("Social links updated; closing modal.");
      setIsSocialLinksModalOpen(false);
      setSocialLinksUpdated(false); // Reset for future use
    }
  }, [socialLinksUpdated]);

  if (loading) {
    //console.log("Loading profile...");
    return <ProfileLoadingSkeleton />;
  }

  if (error) {
    console.error("Error loading profile:", error);
    return <div>Error loading profile: {error}</div>;
  }

  if (!profile) {
    console.warn("No profile found.");
    return <div>No profile found.</div>;
  }

  return (
    <div className="min-h-screen relative bg-gradient-to-r from-purple-50 to-blue-50">
      <div className="max-w-5xl mx-auto p-4 sm:p-8">
        <ProfileHeader
          onEditSocialLinks={() => setIsSocialLinksModalOpen(true)}
        />

        <WishlistSection
          onItemSelect={isOwner ? openEditModal : openViewModal}
          onEditItem={openEditModal}
          isOwner={isOwner}
        />
      </div>

      {isOwner && <FloatingAddButton onClick={() => setIsAddFlowOpen(true)} />}
      {isAddFlowOpen && userId && (
        <AddWishlistFlow userId={userId} onClose={closeAddFlow} />
      )}
      {selectedItem && isEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
            <WishlistItemForm
              item={selectedItem}
              isEditing={true}
              onClose={closeEditModal}
              onSave={handleSaveItem}
            />
          </div>
        </div>
      )}
      {selectedItem && isViewModalOpen && (
        <WishlistItemModal
          item={selectedItem}
          onClose={closeViewModal}
          ownerId={profile?.ownerId || ""}
        />
      )}
      {isSocialLinksModalOpen && isOwner && (
        <SocialLinksModal
          existingSocialLinks={profile.socialLinks}
          onClose={() => {
            //console.log("Modal close attempt detected.");
            setIsSocialLinksModalOpen(false);
          }}
          onUpdate={() => {
            //console.log("Social links updated successfully.");
            setSocialLinksUpdated(true);
          }}
        />
      )}
    </div>
  );
};

export default ProfilePage;
