import React from "react";
import { FaPlus } from "react-icons/fa";

interface FloatingAddButtonProps {
  onClick: () => void;
}

const FloatingAddButton: React.FC<FloatingAddButtonProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="fixed bottom-8 right-8 bg-gradient-to-r from-purple-500 to-blue-500 text-white font-bold py-3 px-6 rounded-full shadow-lg hover:from-blue-600 hover:to-purple-600 transition-all duration-300 flex items-center justify-center"
      aria-label="Add Wishlist Item"
    >
      <FaPlus className="text-lg mr-2" />
      Add Item
    </button>
  );
};

export default FloatingAddButton;
