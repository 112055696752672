import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyOtp, resendOtp } from "../services/authActions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { loginSuccess } from "../redux/authSlice";

const OtpVerificationPage: React.FC = () => {
  const [otpCode, setOtpCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [resendCountdown, setResendCountdown] = useState(30);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  // const savedEmail = sessionStorage.getItem("email");
  const reduxError = useSelector((state: RootState) => state.auth.error);

  const savedEmail = useSelector((state: RootState) => state.auth.user?.email);

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpCode(e.target.value);
    setError(null);
  };
  useEffect(() => {
    if (reduxError && reduxError !== "No token found in cookies") {
      setError(reduxError); // Sync Redux error to local error
    }
  }, [reduxError]);
  const handleVerifyOtp = async () => {
    if (!savedEmail) {
      setError("Email is required to verify OTP");
      return;
    }
    try {
      await dispatch(verifyOtp(savedEmail, otpCode, navigate));
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.message || "OTP verification failed";
      setError(errorMessage); // Set the error to display in the UI
      console.log("Error set in state:", errorMessage); // Debug log to verify error is set
    }
  };

  const handleResendOtp = async () => {
    if (!savedEmail) {
      setError("Email is required to resend OTP");
      return;
    }
    try {
      await dispatch(resendOtp(savedEmail));
      setResendCountdown(30);
    } catch (err: any) {
      setError(err.response?.data?.message || "Failed to resend OTP");
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user") as string)
      : null;
    const storedToken = sessionStorage.getItem("token")
      ? JSON.parse(sessionStorage.getItem("token") as string)
      : null;
    dispatch(
      loginSuccess({ user: storedUser, token: storedToken, isVerified: false })
    );
    // console.log(storedUser);

    if (resendCountdown > 0) {
      const timer = setTimeout(
        () => setResendCountdown(resendCountdown - 1),
        1000
      );
      return () => clearTimeout(timer);
    }
  }, [resendCountdown]);
  // console.log("Rendering with error:", error); // Log to confirm component re-render

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-auth px-4 pb-36 md:pb-20  lg:pb-0">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full space-y-6 ">
        <h2 className="text-2xl font-bold text-gray-800 text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-600">
          Verify Your Account
        </h2>

        <p className="text-center text-gray-600">
          Please enter the OTP sent to your email to verify your account.
        </p>

        <input
          type="text"
          placeholder="Enter OTP"
          value={otpCode}
          onChange={handleOtpChange}
          className="w-full py-3 px-4 bg-gray-100 border rounded-full focus:outline-none"
        />

        {error && <p className="text-red-500 text-center">{error}</p>}

        <button
          onClick={handleVerifyOtp}
          className="w-full py-3 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-full hover:from-blue-600 hover:to-purple-600 transition duration-300"
        >
          Verify OTP
        </button>

        <div className="text-center mt-4">
          {resendCountdown > 0 ? (
            <p className="text-gray-500">
              Resend OTP in {resendCountdown} seconds
            </p>
          ) : (
            <button
              onClick={handleResendOtp}
              className="text-blue-500 hover:underline"
            >
              Resend OTP
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtpVerificationPage;
