import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { fetchWishlistDetails } from "../services/wishlistActions";
import { WishlistItem } from "../../../redux/types";
import { FaTimes, FaGift } from "react-icons/fa";

interface WishlistItemFormProps {
  item?: WishlistItem;
  isEditing?: boolean;
  onClose: () => void;
  onSave: (itemData: WishlistItem) => Promise<void>;
}

const WishlistItemForm: React.FC<WishlistItemFormProps> = ({
  item,
  isEditing = false,
  onClose,
  onSave,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const fetchedItem = useSelector(
    (state: RootState) => state.wishlist.fetchedItem
  );
  const globalLoading = useSelector((state: RootState) => state.auth.loading); // Assuming 'loading' is tracked in auth or a global loader slice

  const [title, setTitle] = useState(item?.title || "");
  const [price, setPrice] = useState(item?.price || "");
  const [currency, setCurrency] = useState(item?.currency || "$");
  const [link, setLink] = useState(item?.link || "");
  const [images, setImages] = useState<string[]>(item?.images || []);
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    item?.selectedImage
  );
  const [description, setDescription] = useState<string>(
    item?.description || ""
  );
  const [priceError, setPriceError] = useState<string | null>(null);

  useEffect(() => {
    if (fetchedItem && !isEditing) {
      setTitle(fetchedItem.title || "");
      setPrice(fetchedItem.price || "");
      setCurrency(fetchedItem.currency || "$");
      setLink(fetchedItem.link || "");
      setImages(fetchedItem.images || []);
      setSelectedImage(fetchedItem.selectedImage);
      setDescription(fetchedItem.description || "");
    }
  }, [fetchedItem, isEditing]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleEsc);
    return () => document.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setter(e.target.value);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setImages((prevImages) => [...prevImages, reader.result as string]);
          setSelectedImage(reader.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isNaN(Number(price))) {
      setPriceError("Please enter a valid number for the price.");
      return;
    }

    const newItem: WishlistItem = {
      _id: item?._id || "new-id",
      title,
      price,
      currency,
      link,
      images,
      selectedImage,
      description,
    };

    try {
      await onSave(newItem);
      onClose();
    } catch (error) {
      console.error("Error saving item:", error);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-out"
      role="dialog"
      aria-modal="true"
      tabIndex={-1}
    >
      <form
        onSubmit={handleSubmit}
        className="relative bg-white rounded-xl shadow-2xl w-full max-w-lg max-h-screen overflow-auto p-6"
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none transition-colors z-30"
          aria-label="Close Modal"
          disabled={globalLoading}
        >
          <FaTimes className="text-xl" />
        </button>

        {/* Modal Title */}
        <h2 className="text-xl font-semibold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
          {isEditing ? "Edit Item" : "Add New Item"}
        </h2>

        {/* Form Fields */}
        <div
          className="grid grid-cols-1 md:grid-cols-2 gap-6"
          aria-disabled={globalLoading}
        >
          <div className="col-span-2">
            <label className="block text-gray-700 font-medium text-sm mb-1">
              Title
            </label>
            <input
              type="text"
              value={title}
              onChange={handleInputChange(setTitle)}
              required
              className="w-full border rounded-md p-2 bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm"
              disabled={globalLoading}
            />
          </div>

          <div className="flex items-center space-x-2 col-span-2">
            <select
              value={currency}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setCurrency(e.target.value)
              }
              className="border rounded-md p-2 bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm"
              disabled={globalLoading}
            >
              <option value="$">$</option>
              <option value="€">€</option>
              <option value="£">£</option>
              <option value="¥">¥</option>
            </select>
            <input
              type="number"
              placeholder="Price"
              value={price}
              onChange={(e) => {
                handleInputChange(setPrice)(e);
                setPriceError(null);
              }}
              required
              min="0"
              className="flex-grow border rounded-md p-2 bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm"
              disabled={globalLoading}
            />
          </div>

          {/* Price error message */}
          {priceError && (
            <p className="text-red-500 text-xs col-span-2">{priceError}</p>
          )}

          <div className="col-span-2">
            <label className="block text-gray-700 font-medium text-sm mb-1">
              Link
            </label>
            <input
              type="url"
              placeholder="Enter URL"
              value={link}
              onChange={handleInputChange(setLink)}
              required
              className="w-full border rounded-md p-2 bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm"
              disabled={globalLoading}
            />
          </div>

          <div className="col-span-2">
            <label className="block text-gray-700 font-medium text-sm mb-1">
              Notes
            </label>
            <textarea
              placeholder="Add some notes"
              value={description}
              onChange={handleInputChange(setDescription)}
              rows={3}
              className="w-full border rounded-md p-2 bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm"
              disabled={globalLoading}
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-2 mt-3 text-white bg-gradient-to-r from-purple-500 to-blue-500 rounded-full hover:from-blue-600 hover:to-purple-600 transition-all duration-300 text-sm"
          disabled={globalLoading}
        >
          {globalLoading
            ? "Saving..."
            : isEditing
            ? "Save Changes"
            : "Add Item"}
        </button>
      </form>
    </div>
  );
};

export default WishlistItemForm;
