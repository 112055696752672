// src/features/profile/redux/profileSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WishlistItem } from '../../../redux/types';

interface ProfileData {
  ownerId: string;
  email: string;
  name: string;
  friendsCount: number;
  socialLinks: {
    twitter?: string;
    instagram?: string;
    tiktok?: string;
    telegram?: string;
  };
  wishlist: WishlistItem[];
  profilePicture: string;
  lists: string[];
}

interface ProfileState {
  data: ProfileData | null;
  loading: boolean;
  error: string | null;
}

const initialState: ProfileState = {
  data: null,
  loading: false,
  error: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // Start loading
    profileStart(state) {
      state.loading = true;
      state.error = null;
    },
    // Success fetching or updating profile
    profileSuccess(state, action: PayloadAction<ProfileData>) {
      state.loading = false;
      state.data = action.payload; // Store the fetched or updated profile
      state.error = null;
    },
    // Error fetching or updating profile
    profileFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
      // Keep existing data in case of failure
    },
  },
});

// Export the actions
export const { profileStart, profileSuccess, profileFailure } = profileSlice.actions;

// Export the reducer to use in the store
export default profileSlice.reducer;
