// authActions.ts

import {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  setUnverifiedUser,
  registerFailure,
} from '../redux/authSlice';
import api from '../../../services/axiosConfig'; // Your Axios instance
import { AppDispatch } from '../../../redux/store'; // Import AppDispatch from your store
import Cookies from 'js-cookie'; // To manage cookies
import { User } from '../redux/authSlice'; // Import the User type
import { NavigateFunction } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import { setLoading } from '../../../redux/uiSlice'; // Import the uiSlice setLoading action

// Login action with isVerified handling
export const login = (email: string, password: string, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  dispatch(setLoading({ isLoading: true, message: "Loggin you in..." }));
  //console.log("logging in");

  try {
    dispatch(loginStart());
    const response = await api.post('/auth/login', { email, password }, { withCredentials: true });

    const { user, token } = response.data;
    const isVerified = user.isVerified; // Correctly access isVerified from within user
    Cookies.set('token', token, { expires: 30, secure: true, sameSite: 'None' }); // Set token in cookies
    // sessionStorage.setItem('user', user);
    // sessionStorage.setItem('token', token);
    // sessionStorage.setItem('email', email);
    //console.log("Cookie set with token:", Cookies.get('token')); // Log token after setting in cookies
    if (!isVerified) {
      dispatch(setUnverifiedUser(user));
      navigate('/verify-otp');
    } else {
      dispatch(loginSuccess({ user, token, isVerified }));
      navigate('/search');
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Login failed';
    if (error.response?.status === 403) {
      const { user, isVerified } = error.response.data;
      //console.log('403 User:', user, 'isVerified:', isVerified);
      dispatch(setUnverifiedUser(user));
      sessionStorage.setItem('user', JSON.stringify(user));

      navigate('/verify-otp');
    } else {
      dispatch(loginFailure(errorMessage));
    }
  } finally {
  dispatch(setLoading({ isLoading: false}));
  }
};

// Facebook Login Action
export const facebookLogin = () => {
  window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/facebook`;
};

export const handleOAuthCallback = () => async (dispatch: AppDispatch) => {
  //console.log("Starting handleOAuthCallback..."); // Start log
  dispatch(setLoading({ isLoading: true }));

  try {
    dispatch(loginStart());

    // Get authorization code from the URL
    const code = new URLSearchParams(window.location.search).get("code");
    //console.log("Authorization code extracted:", code); // Log extracted code

    if (!code) {
      console.warn("Authorization code missing");
      dispatch(loginFailure("Authorization code missing"));
      return;
    }

    // Exchange the code for a token
    const response = await api.post('/auth/exchange-code', { code });
    const { token, isVerified } = response.data;

    //console.log("OAuth response data:", { token, isVerified }); // Log response

    if (token) {
      Cookies.set('token', token, { expires: 30, secure: true, sameSite: 'None', path: '/' });
      //console.log("Token set in cookies:", Cookies.get('token')); // Log token in cookies
      await new Promise(resolve => setTimeout(resolve, 100)); // Pause to ensure the cookie is properly set

      // Decode the token to extract user details
      const decodedToken: any = jwtDecode(token);
      //console.log("Decoded token:", decodedToken); // Log decoded token

      const user: User = {
        _id: decodedToken.id,
        email: decodedToken.email,
        name: decodedToken.name,
        socialLinks: decodedToken.socialLinks || {},
      };

      // Update Redux state after everything is settled
      dispatch(loginSuccess({ user, token, isVerified: decodedToken.isVerified }));
      //console.log("Dispatched loginSuccess:", { user, token, isVerified: decodedToken.isVerified }); // Log the dispatched data
    } else {
      throw new Error("Token was not provided");
    }

  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'OAuth login failed';
    console.error("OAuth error:", error); // Log error
    dispatch(loginFailure(errorMessage));
  } finally {
    dispatch(setLoading({ isLoading: false }));
    //console.log("Completed handleOAuthCallback process."); // Log completion
  }
};




// Google Login Action (Redirect to Google OAuth)
export const googleLogin = () => {
  window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/google`;
};

// Handle Google OAuth callback with isVerified handling
// export const handleGoogleCallback = (navigate: any) => async (dispatch: AppDispatch) => {
//   dispatch(setLoading({ isLoading: true }));

//   try {
//     dispatch(loginStart());
//     const response = await api.get('/auth/verify-token', { withCredentials: true });
//     const { user, token, isVerified } = response.data;
//     Cookies.set('token', token, { expires: 30, secure: true, sameSite: 'None' }); // Set token in cookies

//     dispatch(loginSuccess({ user, token, isVerified }));
//     navigate(isVerified ? '/search' : '/verify-otp');
//   } catch (error: any) {
//     const errorMessage = error.response?.data?.message || 'Google login failed';
//     dispatch(loginFailure(errorMessage));
//   } finally {
//   dispatch(setLoading({ isLoading: false}));
//   }
// };

// Restore session from cookie with isVerified handling and debugging
// Restore session from cookie with isVerified handling and debugging
export const restoreSessionFromCookie = (): any => (dispatch: AppDispatch) => {
  dispatch(setLoading({ isLoading: true }));

  //console.log("Starting restoreSessionFromCookie...");

  const token = Cookies.get("token");

  if (!token) {
    console.warn("No token found in cookies");
    dispatch(loginFailure("No token found in cookies"));
  dispatch(setLoading({ isLoading: false}));
    return;
  }

  try {
    const decodedToken: any = jwtDecode(token);
    //console.log("Decoded token:", decodedToken);

    const user: User = {
      _id: decodedToken.id,
      email: decodedToken.email,
      name: decodedToken.name,
      socialLinks: decodedToken.socialLinks || {},
    };

    dispatch(loginSuccess({ user, token, isVerified: decodedToken.isVerified }));
    //console.log("Dispatched loginSuccess with:", { user, token, isVerified: decodedToken.isVerified });
  } catch (error) {
    console.error("Failed to restore session from cookie:", error);
    dispatch(loginFailure("Session restoration failed"));
  } finally {
  dispatch(setLoading({ isLoading: false}));
    //console.log("Completed restoreSessionFromCookie process.");
  }
};
export const register = (email: string, password: string, name: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading({ isLoading: true, message: "Registering..." }));

  try {
    dispatch(loginStart());
    const response = await api.post('/auth/register', { email, password, name });
    const { user, token } = response.data;

    // Cookies.set('token', token, { expires: 1, secure: true, sameSite: 'None' });
    sessionStorage.setItem('user', JSON.stringify(user));

    dispatch(loginSuccess({ user, token, isVerified: false }));
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Registration failed';
    dispatch(registerFailure(errorMessage)); // Dispatch specific registration failure
    throw new Error(errorMessage); // Optionally throw for component-level handling
  } finally {
    dispatch(setLoading({ isLoading: false }));
  }
};

// Logout User (Frontend + Backend)
export const logoutUser = (navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  dispatch(setLoading({ isLoading: true, message: "Logging you out..." }));

  try {
    await api.post('/auth/logout');
        Cookies.remove('token', { path: '/' }); // Clear token cookie on frontend

    dispatch(logout());
    navigate('/login');
  } catch (error) {
    console.error('Logout failed:', error);
    dispatch(logout());
    navigate('/login');
  } finally {
  dispatch(setLoading({ isLoading: false}));
  }
};

// Verify authentication on page load with debugging
export const verifyAuth = () => async (dispatch: AppDispatch) => {

  dispatch(setLoading({ isLoading: true }));

  try {
    dispatch(loginStart());
    const response = await api.get("/auth/verify-token", { withCredentials: true });
    const { user, token } = response.data;  // Retrieve token here if needed
    const isVerified = user.isVerified;
    Cookies.set('token', token, { expires: 30, secure: true, sameSite: 'None' }); // Set token in cookies

    dispatch(loginSuccess({ user, token, isVerified }));  // Pass token from response
  } catch (error: any) {
    console.error("Authentication verification failed:", error);
    dispatch(loginFailure("Authentication failed"));
  } finally {
  dispatch(setLoading({ isLoading: false}));
  }
};
export const verifyOtp = (email: string, otpCode: string, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
  dispatch(setLoading({ isLoading: true, message: "Verifying OTP..." }));

  try {
    // //console.log(`Sending OTP verification request with email: ${email} and OTP: ${otpCode}`);
    const response = await api.post('/auth/verify-otp', { email, otp: otpCode });

    // Check for success and presence of token
    if (response.data.message === "Account verified successfully" && response.data.token) {
      const { user, token } = response.data;

      // Store token in cookies and dispatch login success
      Cookies.set('token', token, { expires: 30, secure: true, sameSite: 'None' });

      dispatch(loginSuccess({ user, token, isVerified: true }));

      // Only navigate if verification was successful
      navigate('/search');
      //console.log("Navigated to '/search' after OTP verification.");
    } else {
      // Handle unsuccessful verification
      console.error("OTP verification failed or token missing in response:", response.data);
      dispatch(loginFailure("OTP verification failed"));
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'OTP verification failed';
    console.error("Error during OTP verification request:", error);
    dispatch(loginFailure(errorMessage));
  } finally {
    dispatch(setLoading({ isLoading: false }));
  }
};



// Resend OTP Action
export const resendOtp = (email: string) => async () => {
  try {
    await api.post('/auth/resend-otp', { email });
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Failed to resend OTP';
    throw new Error(errorMessage);
  }
};


