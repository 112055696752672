import React from "react";
import { FaSpinner, FaGift } from "react-icons/fa";

interface LoadingModalProps {
  isOpen: boolean;
  message?: string;
  isLoading?: boolean;
}

const LoadingModal: React.FC<LoadingModalProps> = ({
  isOpen,
  message,
  isLoading = true,
}) => {
  if (!isOpen) return null;

  const defaultMessages = [
    "Getting things ready for you...",
    "A little magic is on the way!",
    "Almost there, just a moment...",
    "We’re wrapping up your request...",
  ];
  const displayMessage =
    message ||
    defaultMessages[Math.floor(Math.random() * defaultMessages.length)];

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1000]">
      <div className="bg-white rounded-lg shadow-lg py-6 px-2 w-full max-w-xs md:max-w-sm text-center space-y-3 h-[150px] md:h-[200px] flex flex-col justify-center">
        {/* Icon */}
        <div className="flex justify-center mb-2">
          <FaGift className="text-purple-500 text-xl md:text-2xl animate-bounce" />
        </div>

        {/* Message */}
        <p className="text-gray-800 font-medium text-sm md:text-base">
          {displayMessage}
        </p>

        {/* Spinner */}
        {/* {isLoading && (
          <div className="flex justify-center mt-2">
            <FaSpinner className="animate-spin text-blue-500 text-lg" />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default LoadingModal;
