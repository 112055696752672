import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface ProtectedRouteProps {
  userId?: string;
  children?: React.ReactNode; // Accept children as a prop
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  userId,
  children,
}) => {
  const { isAuthenticated, isVerified, user } = useSelector(
    (state: RootState) => state.auth
  );

  const isOwnProfile = userId ? userId === user?._id : true;

  if (!isAuthenticated) return <Navigate to="/login" />;

  if (isAuthenticated && !isVerified) return <Navigate to="/verify-otp" />;

  if (isAuthenticated && isVerified && !isOwnProfile)
    return <Navigate to="/" />;

  return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;
