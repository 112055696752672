import React, { useState, useEffect } from "react";
import { FaSearch, FaGift } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import FilterBar from "./FilterBar";
import { ALL_PLATFORMS } from "../../../constants/constants";

const SearchWithFilterBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const initialSearchQuery = queryParams.get("query") || "";
  const initialSortBy = queryParams.get("sortBy") || "";
  const initialPlatforms = queryParams.getAll("platforms");

  const [platforms, setPlatforms] = useState<string[]>(
    initialPlatforms.length > 0 ? initialPlatforms : ALL_PLATFORMS
  );
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [loading, setLoading] = useState(false);

  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
    null
  );

  const updateURL = () => {
    const params = new URLSearchParams();
    if (searchQuery) params.set("query", searchQuery);
    if (sortBy) params.set("sortBy", sortBy);
    platforms.forEach((platform) => params.append("platforms", platform));
    console.log("Navigating with URL:", `/search/results?${params.toString()}`);
    navigate(`/search/results?${params.toString()}`, { replace: true });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);

    if (debounceTimer) clearTimeout(debounceTimer);

    const newTimer = setTimeout(() => {
      updateURL();
    }, 300);

    setDebounceTimer(newTimer);
  };

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    updateURL();
    setTimeout(() => setLoading(false), 500);
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchQuery) params.set("query", searchQuery);
    if (sortBy) params.set("sortBy", sortBy);
    platforms.forEach((platform) => params.append("platforms", platform));

    navigate(`/search/results?${params.toString()}`, { replace: true });
  }, [searchQuery, sortBy, platforms, navigate]);

  return (
    <form
      onSubmit={handleSearchSubmit}
      className="w-full max-w-6xl mx-auto px-4 pt-8 sm:pt-12 md:pt-8 lg:pt-4"
    >
      {/* Search Bar */}
      <div className="flex items-center bg-white rounded-full shadow-lg p-1 sm:p-2 hover:shadow-xl transition-all duration-300 border-2 border-purple-200">
        <FaSearch className="text-purple-400 ml-4 sm:ml-2" />
        <input
          type="text"
          placeholder="Search by name or social media handle..."
          className="flex-grow p-2 sm:p-3 rounded-l-full focus:outline-none text-gray-800 ml-2 text-sm sm:text-base"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button
          type="submit"
          className={`bg-pink-500 text-white rounded-full p-3 hover:bg-pink-600 transition-transform transform hover:scale-105 duration-300 flex items-center ${
            loading ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={loading}
          aria-label="Search"
        >
          {loading ? (
            <FaGift className="animate-spin text-lg" />
          ) : (
            <FaGift className="text-lg" />
          )}
        </button>
      </div>

      {/* Filter Bar */}
      <div className="mt-4">
        <FilterBar
          sortBy={sortBy}
          setSortBy={setSortBy}
          platforms={platforms}
          setPlatforms={setPlatforms}
        />
      </div>
    </form>
  );
};

export default SearchWithFilterBar;
