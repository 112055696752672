// authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  _id: string;
  email: string;
  name: string;
  socialLinks?: {
    instagram?: string;
    tiktok?: string;
    telegram?: string;
  };
}

interface AuthState {
  user: User | null;
  token: string | null;
  loading: boolean;
  error: string | null;
  isAuthenticated: boolean;
  isVerified: boolean;
  email: string | null; // New property for email

}

const initialState: AuthState = {
  user: null,
  token: null,
  loading: false,
  error: null,
  isAuthenticated: false,
  isVerified: false,
    email: null, // Initialize email to null

};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    loginStart(state) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action: PayloadAction<{ user: User; token: string; isVerified: boolean }>) {
      state.loading = false;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isVerified = action.payload.isVerified;
      // state.email = action.payload.user.email;

    },
    setUnverifiedUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.isVerified = false;
      state.loading = false;
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
      state.token = null;
      state.isVerified = false;
    },
        registerFailure(state, action: PayloadAction<string>) { // New register failure handler
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
      state.token = null;
      state.isVerified = false;
    },
    logout(state) {
      state.loading = false;
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.isVerified = false;
    },setEmail(state, action: PayloadAction<string>) {
  state.email = action.payload;

},
clearError(state) { // New action to clear the error
      state.error = null;
    },
  },
});

export const { setLoading, loginStart, loginSuccess, setUnverifiedUser, loginFailure,registerFailure, logout,setEmail, clearError } = authSlice.actions;
export default authSlice.reducer;
