import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { searchFriendsWithFilters } from '../services/searchActions';

interface SearchState {
  searchQuery: string;
  searchResults: any[];
  loading: boolean;
  error: string | null;
}

const initialState: SearchState = {
  searchQuery: '',
  searchResults: [],
  loading: false,
  error: null,
};

export const searchForFriendsWithFilters = createAsyncThunk(
  'search/searchForFriendsWithFilters',
  async ({
    query,
    sortBy,
    platforms,
  }: {
    query?: string;
    sortBy?: string;
    platforms?: string[];
  }) => {
    return await searchFriendsWithFilters({ query, sortBy, platforms: platforms });
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchForFriendsWithFilters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchForFriendsWithFilters.fulfilled, (state, action) => {
        state.loading = false;
        state.searchResults = Array.isArray(action.payload) ? action.payload : [];
      })
      .addCase(searchForFriendsWithFilters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong';
      });
  },
});

export default searchSlice.reducer;
