import React, { useState } from "react";
import { FaLock, FaEnvelope } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { login, facebookLogin } from "../services/authActions";
import { useNavigate, Link } from "react-router-dom";
import SocialLoginButtons from "./SocialLoginButtons";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(login(email, password, navigate));
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-gradient-auth px-4 pb-36 md:pb-20  lg:pb-0">
      <div
        className={`bg-white shadow-lg rounded-lg p-8 max-w-lg w-full space-y-6 transform transition-all duration-500 ${
          loading ? "opacity-50" : ""
        }`}
      >
        <h2 className="text-2xl font-bold text-gray-800 text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-600">
          Welcome Back
        </h2>

        {/* Login Form */}
        <form onSubmit={handleSubmit} className="space-y-4 px-4 md:px-8 py-4">
          {/* Email Input */}
          <div className="relative border border-transparent bg-gradient-to-r from-purple-400 to-blue-400 rounded-full p-0.5">
            <div className="relative rounded-full bg-white">
              <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full py-3 pl-10 pr-4 bg-transparent border-none rounded-full focus:outline-none"
                required
                disabled={loading}
              />
            </div>
          </div>

          {/* Password Input */}
          <div className="relative border border-transparent bg-gradient-to-r from-purple-400 to-blue-400 rounded-full p-0.5">
            <div className="relative rounded-full bg-white">
              <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full py-3 pl-10 pr-4 bg-transparent border-none rounded-full focus:outline-none"
                required
                disabled={loading}
              />
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-3 text-white bg-gradient-to-r from-purple-500 to-blue-500 rounded-full hover:from-blue-600 hover:to-purple-600 transition-all duration-300"
            disabled={loading}
          >
            {loading ? "Logging in..." : "Login"}
          </button>

          {error &&
            error !== "Authentication failed" &&
            error !== "No token found in cookies" && (
              <p className="text-red-500 text-center">{error}</p>
            )}
        </form>

        {/* OR Divider */}
        <div className="flex items-center my-6">
          <div className="flex-grow h-px bg-gray-300"></div>
          <span className="px-4 text-gray-500 text-sm">OR</span>
          <div className="flex-grow h-px bg-gray-300"></div>
        </div>

        {/* Social Login Buttons */}
        <SocialLoginButtons onFacebookLogin={facebookLogin} />

        {/* Navigation to Registration Page */}
        <div className="text-center mt-6">
          <p className="text-sm text-gray-600">
            Don’t have an account?{" "}
            <Link to="/register" className="text-blue-500 hover:underline">
              Create one here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
