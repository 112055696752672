import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { useLocation } from "react-router-dom";
import { searchForFriendsWithFilters } from "../redux/searchSlice";
import SearchTile from "./SearchTile";
import SearchWithFilterBar from "./SearchWithFilterBar";
import SkeletonSearchTile from "./SkeletonSearchTile";

const SearchResultsPage: React.FC = () => {
  const searchResults = useSelector(
    (state: RootState) => state.search.searchResults
  );
  const loading = useSelector((state: RootState) => state.search.loading);
  const error = useSelector((state: RootState) => state.search.error);

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query") || "";
    const sortBy = queryParams.get("sortBy") || "";
    const platforms = queryParams.getAll("platforms");

    // Dispatch search if the query is non-empty
    if (query.trim()) {
      dispatch(
        searchForFriendsWithFilters({
          query,
          sortBy,
          platforms,
        })
      );
    }
  }, [location.search, dispatch]);

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-red-500 text-xl">Error: {error}</div>
      </div>
    );
  }

  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query") || "";

  return (
    <div className="min-h-screen p-4 sm:p-8 bg-gradient-to-r from-purple-50 to-blue-50">
      <div className="max-w-6xl mx-auto">
        <SearchWithFilterBar />
      </div>

      {loading ? (
        <div className="max-w-6xl mx-auto px-4 sm:px-8 mt-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            <SkeletonSearchTile />
            <SkeletonSearchTile />
            <SkeletonSearchTile />
          </div>
        </div>
      ) : query.trim() ? (
        searchResults.length > 0 ? (
          <div className="max-w-6xl mx-auto px-4 sm:px-8 mt-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
              {searchResults.map((result: any, index: number) => (
                <SearchTile
                  key={index}
                  user_id={result._id}
                  name={result.name}
                  profilePicture={result.profilePicture}
                  socialLinks={result.socialLinks}
                />
              ))}
            </div>
          </div>
        ) : (
          <p className="text-center text-gray-700 text-lg mt-4">
            No users found.
          </p>
        )
      ) : (
        <p className="text-center text-black-700 text-lg mt-32">
          Enter your loved one's name or social media handles (Instagram,
          TikTok, Telegram)
        </p>
      )}
    </div>
  );
};

export default SearchResultsPage;
