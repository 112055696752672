// src/hooks/useOwnership.ts
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { checkOwnership } from '../services/checkOwnership';

export const useOwnership = (resourceOwnerId: string | undefined): boolean => {
  const authenticatedUser = useSelector((state: RootState) => state.auth.user);
  if (!authenticatedUser || !resourceOwnerId) return false;

  return checkOwnership(authenticatedUser._id, resourceOwnerId);
};
