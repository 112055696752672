import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { updateProfileSocialLinks } from "../services/profileActions";
import { FaInstagram, FaTiktok, FaTelegramPlane } from "react-icons/fa";
import { setLoading } from "../../../redux/uiSlice";

interface SocialLinksModalProps {
  existingSocialLinks?: {
    instagram?: string;
    tiktok?: string;
    telegram?: string;
  };
  onClose: () => void;
  onUpdate: () => void; // Triggered on successful update
}

const SocialLinksModal: React.FC<SocialLinksModalProps> = ({
  existingSocialLinks = {},
  onClose,
  onUpdate,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [instagram, setInstagram] = useState(
    existingSocialLinks.instagram || ""
  );
  const [tiktok, setTiktok] = useState(existingSocialLinks.tiktok || "");
  const [telegram, setTelegram] = useState(existingSocialLinks.telegram || "");
  const [error, setError] = useState("");

  const isLoading = useSelector((state: RootState) => state.ui.isLoading);

  const [instagramError, setInstagramError] = useState("");
  const [tiktokError, setTiktokError] = useState("");
  const [telegramError, setTelegramError] = useState("");

  const validateInstagramHandle = (handle: string): boolean => {
    const regex = /^[a-zA-Z0-9._]{1,30}$/;
    return regex.test(handle);
  };

  const validateTikTokHandle = (handle: string): boolean => {
    const regex = /^[a-zA-Z0-9_]{2,24}$/;
    return regex.test(handle);
  };

  const validateTelegramHandle = (handle: string): boolean => {
    const regex = /^[a-zA-Z][a-zA-Z0-9_]{4,31}$/;
    return regex.test(handle);
  };

  const handleInstagramChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInstagram(value);
    setInstagramError(
      value && !validateInstagramHandle(value)
        ? "Invalid Instagram handle."
        : ""
    );
  };

  const handleTikTokChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTiktok(value);
    setTiktokError(
      value && !validateTikTokHandle(value) ? "Invalid TikTok handle." : ""
    );
  };

  const handleTelegramChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTelegram(value);
    setTelegramError(
      value && !validateTelegramHandle(value) ? "Invalid Telegram handle." : ""
    );
  };

  const isFormValid =
    (!instagramError && instagram) ||
    (!tiktokError && tiktok) ||
    (!telegramError && telegram);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid) {
      setError("Please provide at least one valid social handle.");
      //console.log("Form invalid, update aborted.");
      return;
    }

    dispatch(setLoading({ isLoading: true, message: "Updating profile..." }));

    try {
      await dispatch(updateProfileSocialLinks({ instagram, tiktok, telegram }));
      //console.log("Dispatch to update profile social links succeeded.");
      onUpdate();
    } catch (err) {
      setError("Error updating social links.");
      console.error("Error during profile update:", err);
    } finally {
      dispatch(setLoading({ isLoading: false }));
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center  bg-black bg-opacity-50 z-10">
      <div className="bg-white rounded-2xl shadow-2xl p-8 w-full max-w-lg relative">
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
          Help Your Friends Discover Your Wishlist!
        </h2>
        {error && (
          <p className="text-red-500 mb-4 text-center font-medium">{error}</p>
        )}
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Instagram Input */}
          <div>
            <label className="block text-gray-700 font-semibold mb-1">
              Instagram Handle
            </label>
            <div className="flex items-center border rounded-md overflow-hidden">
              <span className="px-3 text-gray-600">
                <FaInstagram className="text-pink-500 text-xl" />
              </span>
              <input
                type="text"
                value={instagram}
                onChange={handleInstagramChange}
                className="flex-grow p-2 focus:outline-none text-gray-700"
                placeholder="your_username"
              />
            </div>
            {instagramError && (
              <p className="text-red-500 text-sm mt-1">{instagramError}</p>
            )}
          </div>

          {/* TikTok Input */}
          <div>
            <label className="block text-gray-700 font-semibold mb-1">
              TikTok Handle
            </label>
            <div className="flex items-center border rounded-md overflow-hidden">
              <span className="px-3 text-gray-600">
                <FaTiktok className="text-black text-xl" />
              </span>
              <input
                type="text"
                value={tiktok}
                onChange={handleTikTokChange}
                className="flex-grow p-2 focus:outline-none text-gray-700"
                placeholder="your_username"
              />
            </div>
            {tiktokError && (
              <p className="text-red-500 text-sm mt-1">{tiktokError}</p>
            )}
          </div>

          {/* Telegram Input */}
          <div>
            <label className="block text-gray-700 font-semibold mb-1">
              Telegram Handle
            </label>
            <div className="flex items-center border rounded-md overflow-hidden">
              <span className="px-3 text-gray-600">
                <FaTelegramPlane className="text-blue-500 text-xl" />
              </span>
              <input
                type="text"
                value={telegram}
                onChange={handleTelegramChange}
                className="flex-grow p-2 focus:outline-none text-gray-700"
                placeholder="your_username"
              />
            </div>
            {telegramError && (
              <p className="text-red-500 text-sm mt-1">{telegramError}</p>
            )}
          </div>

          <button
            type="submit"
            disabled={!isFormValid || isLoading}
            className={`w-full py-3 text-white rounded-md font-semibold ${
              isFormValid && !isLoading
                ? "bg-gradient-to-r from-purple-500 to-blue-500"
                : "bg-gray-400 cursor-not-allowed"
            }`}
          >
            {isLoading ? "Submitting..." : "Let's Go!"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SocialLinksModal;
