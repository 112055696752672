import React from "react";

const ProfileLoadingSkeleton: React.FC = () => {
  return (
    <div className="min-h-screen relative bg-gradient-to-r from-purple-50 to-blue-50 flex items-center justify-center">
      <div className="max-w-5xl mx-auto p-4 sm:p-8">
        <div className="min-h-screen pt-2 md:pt-8 pb-0 px-6 lg:px-20 max-w-5xl mx-auto  animate-pulse border-2 border-purple-300 rounded-lg bg-white shadow-md">
          {/* Profile Header Skeleton */}
          <div className="pt-8 pb-4 px-6 lg:px-20 rounded-t-lg">
            <div className="flex flex-col items-center space-y-4">
              <div className="h-8 bg-gray-300 rounded w-40"></div>{" "}
              {/* Name Placeholder */}
              <div className="flex items-center space-x-4 mt-4 divide-x divide-gray-300">
                <div className="h-6 w-24 bg-gray-300 rounded"></div>
                <div className="h-6 w-24 bg-gray-300 rounded"></div>
                <div className="h-6 w-24 bg-gray-300 rounded"></div>
              </div>
            </div>
          </div>

          {/* Divider with Icon */}
          <div className="relative flex items-center my-6">
            <div className="flex-grow h-0.5 bg-gradient-to-r from-blue-500 to-purple-500"></div>
            <div className="absolute left-1/2 transform -translate-x-1/2 -top-3 bg-white px-2">
              <div className="h-6 w-6 bg-gray-300 rounded-full"></div>{" "}
              {/* Gift icon placeholder */}
            </div>
            <div className="flex-grow h-0.5 bg-gradient-to-r from-purple-500 to-blue-500"></div>
          </div>

          {/* Wishlist Items Skeleton */}
          <div className="pt-2 pb-32 px-6 sm:px-8 lg:pt-10 lg:px-40 min-h-[60vh]">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {[1, 2].map((_, index) => (
                <div
                  key={index}
                  className="border-2 border-purple-300 h-24 p-4 bg-gray-200 rounded-lg flex items-center justify-between "
                >
                  <div className="flex items-center space-x-3">
                    <div className="h-6 w-6 bg-purple-300 rounded-full"></div>{" "}
                    {/* Icon Placeholder */}
                    <div className="h-4 bg-gray-300 rounded w-20"></div>{" "}
                    {/* Item name placeholder */}
                  </div>
                  <div className="h-4 w-10 bg-gray-300 rounded"></div>{" "}
                  {/* Price Placeholder */}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Placeholder for Add Button */}
        <div className="fixed bottom-8 right-8">
          <div className="h-12 w-12 bg-gradient-to-r from-purple-400 to-blue-400 rounded-full flex items-center justify-center">
            <div className="h-6 w-6 bg-gray-200 rounded-full"></div>{" "}
            {/* Icon Placeholder */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileLoadingSkeleton;
