import React from "react";

const SloganSection: React.FC = () => {
  return (
    <div className="text-center text-white mb-6 sm:mb-10 animate-fadeIn">
      <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-3 sm:mb-4 leading-snug sm:leading-tight">
        Give the Perfect Gift. Every Time.
      </h1>
      <p className="text-base sm:text-lg lg:text-xl text-white/90 leading-relaxed">
        Discover wishlists and make gift-giving effortless.
      </p>
    </div>
  );
};

export default SloganSection;

// import React, { useState, useEffect } from "react";

// const SloganSection: React.FC = () => {
//   const [isGive, setIsGive] = useState(true);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setIsGive((prev) => !prev);
//     }, 4000); // Toggle every 2 seconds
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className="text-center text-white mb-10 animate-fadeIn">
//       <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4">
//         <span className="relative">
//           {/* Static text */}
//           <span className="inline">
//             {/* Container for the animated word */}
//             <span className="inline-block relative w-[140px] h-[52px] text-right">
//               <span
//                 className={`absolute top-0 left-0 w-full transition-transform duration-500 ease-in-out ${
//                   isGive ? "translate-y-0" : "-translate-y-full"
//                 }`}
//               >
//                 Give
//               </span>
//               <span
//                 className={`absolute top-0 left-0 w-full transition-transform duration-500 ease-in-out ${
//                   isGive ? "translate-y-full" : "translate-y-0"
//                 }`}
//               >
//                 Get
//               </span>
//             </span>{" "}
//             the Perfect Gift. Every Time.
//           </span>
//         </span>
//       </h1>
//       <p className="text-lg sm:text-xl text-white/90">
//         Discover wishlists and make gift-giving effortless.
//       </p>
//     </div>
//   );
// };

// export default SloganSection;
