import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store"; // Adjust path if needed
import { handleOAuthCallback } from "../services/authActions"; // Adjust path if needed
import { FaSpinner } from "react-icons/fa"; // For spinner icon (install react-icons if not installed)

const OAuthCallbackPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const hasCalledCallback = useRef(false);

  const { isAuthenticated, loading, token } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    if (!hasCalledCallback.current) {
      dispatch(handleOAuthCallback());
      hasCalledCallback.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && !loading && token) {
      setTimeout(() => navigate("/search"), 300);
    }
  }, [isAuthenticated, loading, token, navigate]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-search px-4">
      <div className="flex items-center space-x-3 text-center text-white">
        <FaSpinner className="animate-spin text-2xl" />
        <p className="text-lg">Connecting you to your account...</p>
      </div>
    </div>
  );
};

export default OAuthCallbackPage;
