import React, { useState, useRef, useEffect } from "react";
import { FaSortAlphaDown, FaFilter, FaChevronDown } from "react-icons/fa";

// Import the list of all platforms
export const ALL_PLATFORMS = ["instagram", "tiktok", "telegram"];

interface FilterBarProps {
  sortBy: string;
  setSortBy: (value: string) => void;
  platforms: string[];
  setPlatforms: (value: string[]) => void;
}

const FilterBar: React.FC<FilterBarProps> = ({
  sortBy,
  setSortBy,
  platforms,
  setPlatforms,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePlatformChange = (platform: string) => {
    if (platforms.includes(platform)) {
      // If all platforms were selected and one is deselected, remove it from the list
      setPlatforms(platforms.filter((p) => p !== platform));
    } else {
      // Add the platform to the selected list
      setPlatforms([...platforms, platform]);
    }
  };

  return (
    <div className="flex flex-wrap justify-start gap-4 mt-4 max-w-6xl mx-auto pr-4 pl-4">
      {/* Sort By */}
      <div className="relative w-auto flex items-center">
        <FaSortAlphaDown className="absolute left-3 text-gray-500" />
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="appearance-none bg-white pl-10 pr-6 py-1.5 rounded-full border border-gray-300 text-sm text-gray-700 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition-all"
        >
          <option value="">Sort By</option>
          <option value="name-asc">Name (A-Z)</option>
          <option value="name-desc">Name (Z-A)</option>
        </select>
      </div>

      {/* Platform Filter */}
      <div className="relative w-auto">
        <button
          type="button"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex items-center bg-white pl-4 pr-4 py-1.5 rounded-full border border-gray-300 text-sm text-gray-700 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition-all"
        >
          <FaFilter className="text-gray-500 mr-2" />
          {platforms.length === 0 ||
          platforms.length === ALL_PLATFORMS.length ? (
            <span>All Platforms</span>
          ) : (
            <span>{platforms.join(", ")}</span>
          )}
          <FaChevronDown className="ml-2 text-gray-500" />
        </button>

        {isDropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute mt-2 w-48 bg-white rounded-md shadow-lg z-10"
          >
            <ul className="py-1">
              {ALL_PLATFORMS.map((platform) => (
                <li key={platform} className="px-4 py-2 hover:bg-gray-100">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      checked={platforms.includes(platform)}
                      onChange={() => handlePlatformChange(platform)}
                    />
                    <span className="ml-2 capitalize">{platform}</span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBar;
