import { WishlistItem } from '../../../redux/types'; // Import WishlistItem type
import { setFetchedItem, toggleReservation } from '../redux/wishlistSlice';
import { AppDispatch, RootState } from '../../../redux/store'; // Adjust this to your actual store setup
import axios from 'axios';
import { setWishlistItems } from '../redux/wishlistSlice';
import { profileSuccess } from '../../profile/redux/profileSlice';
import { setLoading } from '../../../redux/uiSlice';

// Simulate a fetch function for wishlist details based on the URL
export const fetchWishlistDetails = (url: string) => async (dispatch: AppDispatch) => {
  try {
    // Simulated API call: replace this with your actual API call using fetch or axios
    const simulatedApiResponse: WishlistItem = {
      _id: 'temp-id', // Use _id instead of id
      title: '',
      price: '',
      currency: '$',
      link: url, // Primary link
      selectedImage: undefined
    };

    // Dispatch the data to the Redux store
    dispatch(setFetchedItem(simulatedApiResponse));
  } catch (error) {
    console.error('Error fetching wishlist details:', error);
  }
};
export const fetchWishlistItems = (wishlistIds: string[]) => async (dispatch: AppDispatch) => {
  //console.log("fetchWishlistItems called with IDs:", wishlistIds);
  try {
    const response = await axios.post('/wishlist/fetchWishlistItems', { wishlistIds });
    //console.log("API response:", response.data); // Log response to verify data structure
    dispatch(setWishlistItems(response.data));
  } catch (error) {
    console.error("Error fetching wishlist items:", error);
  }
};


// Example for addNewWishlistItem action
export const addNewWishlistItem = (item: WishlistItem) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading({ isLoading: true, message: 'Adding new item...' }));
  try {
    const state = getState();
    const userId = state.auth.user?._id;
    if (!userId) throw new Error('User not authenticated');

    const response = await axios.post('/wishlist/add', { ...item, userId }, { data: { userId },  withCredentials: true});


    const updatedProfileResponse = await axios.get(`/profile/${userId}`);
    dispatch(profileSuccess(updatedProfileResponse.data));
  } catch (error) {
    console.error('Error adding wishlist item:', error);
  } finally {
    dispatch(setLoading({ isLoading: false }));
  }
};


export const deleteWishlistItem = (itemId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {

  try {
    const state = getState();
    const userId = state.auth.user?._id;

    if (!userId) {
      throw new Error('User not authenticated');
    }

    //console.log("Attempting to delete item with ID:", itemId); // Log the itemId

    // Send request to delete the item
    const response = await axios.delete(`/wishlist/delete/${itemId}`, { data: { userId },  withCredentials: true});

    //console.log("Delete response:", response.data); // Log the response from the backend

    // Fetch updated profile after deletion
    const updatedProfileResponse = await axios.get(`/profile/${userId}`);
    dispatch(profileSuccess(updatedProfileResponse.data));
  } catch (error) {
    console.error('Error deleting wishlist item:', error);
  }
};
export const editWishlistItem = (itemId: string, updatedItem: WishlistItem) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(setLoading({ isLoading: true, message: 'Saving changes...' })); // Start loading
  try {
    const state = getState();
    const userId = state.auth.user?._id;

    if (!userId) {
      throw new Error('User not authenticated');
    }

    // Send request to edit the item
    const response = await axios.put(`/wishlist/edit/${itemId}`, { ...updatedItem, userId });

    // Fetch updated profile after editing
    const updatedProfileResponse = await axios.get(`/profile/${userId}`);
    dispatch(profileSuccess(updatedProfileResponse.data));
  } catch (error) {
    console.error('Error editing wishlist item:', error);
  } finally {
    dispatch(setLoading({ isLoading: false })); // Stop loading
  }
};

// wishlistActions.ts
// wishlistActions.ts
export const toggleReserveItem = (itemId: string) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const state = getState();
  const ownerId = state.profile.data?.ownerId;
  const currentUserId = state.auth.user?._id;

  if (!ownerId || !currentUserId) throw new Error('User or profile owner not available');

  const item = state.wishlist.wishlistItems.find((i) => i._id === itemId);
  if (!item) throw new Error('Item not found');

  const isReservedByCurrentUser = item.reservedBy === currentUserId;

  // Determine the new reservedBy value
  const newReservedBy = isReservedByCurrentUser ? null : currentUserId;

  try {
    await axios.put(
      `/wishlist/reserve/${itemId}?targetUserId=${ownerId}`,
      { userId: newReservedBy },
      { withCredentials: true }
    );

    dispatch(toggleReservation({ itemId, userId: newReservedBy }));
  } catch (error) {
    console.error('Error reserving wishlist item:', error);
  }
};






