import React from "react";
import { FaGift } from "react-icons/fa";
import WishlistTile from "../../wishlist/components/WishlistTile";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { WishlistItem } from "../../../redux/types";
import { deleteWishlistItem } from "../services/wishlistActions";
import { setLoading } from "../../../redux/uiSlice"; // Import global loading action

interface WishlistSectionProps {
  onItemSelect: (item: WishlistItem) => void;
  onEditItem: (item: WishlistItem) => void;
  isOwner: boolean;
}

const WishlistSection: React.FC<WishlistSectionProps> = ({
  onItemSelect,
  onEditItem,
  isOwner,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.profile.data);
  const wishlistItems = profile?.wishlist || [];

  const globalLoading = useSelector((state: RootState) => state.ui.isLoading); // Assuming loading state is tracked globally

  const handleDeleteItem = async (itemId: string) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      dispatch(setLoading({ isLoading: true })); // Start global loading
      await dispatch(deleteWishlistItem(itemId));
      dispatch(setLoading({ isLoading: false })); // Stop global loading
    }
  };

  return (
    <div className="min-h-screen">
      <div className="bg-white pt-2 md:pt-6 pb-32 px-6 sm:px-8 lg:pt-10 lg:px-20 rounded-b-lg shadow-md max-w-5xl mx-auto min-h-[60vh] border-l-2 border-r-2 border-b-2 border-purple-400">
        <div className="relative flex items-center my-10">
          <div className="flex-grow h-0.5 bg-gradient-to-r from-blue-500 to-purple-500"></div>
          <div className="absolute left-1/2 transform -translate-x-1/2 -top-3 bg-white px-2">
            <FaGift className="text-purple-500 h-6 w-6" />
          </div>
          <div className="flex-grow h-0.5 bg-gradient-to-r from-purple-500 to-blue-500"></div>
        </div>

        <h2 className="hidden sm:block text-center text-lg sm:text-xl font-semibold text-gray-800 mt-6 mb-4">
          Wishlist
        </h2>

        <div className="pt-0 md:pt-6">
          {wishlistItems.length === 0 ? (
            <p className="text-center text-gray-500 text-xs sm:text-sm">
              No wishlist items found. Add some to get started!
            </p>
          ) : (
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2">
              {wishlistItems.map((item) => (
                <div
                  key={item._id}
                  className="transform transition-all duration-200 hover:scale-105 hover:shadow-lg"
                  onClick={() =>
                    isOwner ? onEditItem(item) : onItemSelect(item)
                  }
                >
                  <WishlistTile
                    item={item}
                    onItemSelect={onItemSelect}
                    onEditItem={onEditItem}
                    onDeleteItem={handleDeleteItem}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WishlistSection;
