import React, { useState } from "react";
import { AppDispatch } from "../../../redux/store";
import { fetchWishlistDetails } from "../services/wishlistActions";
import { useDispatch } from "react-redux";
import { FaGift } from "react-icons/fa";

interface AddWishlistItemFormProps {
  onAddItem: () => void;
}

const AddWishlistItemForm: React.FC<AddWishlistItemFormProps> = ({
  onAddItem,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [url, setUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (url) {
      setLoading(true);
      setTimeout(() => {
        dispatch(fetchWishlistDetails(url));
        setLoading(false);
        onAddItem();
      }, 1000);
    } else {
      onAddItem();
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-6 pt-8 pb-3 px-10 bg-white rounded-lg"
    >
      {/* URL Field */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="col-span-1 md:col-span-2">
          <label className="block text-gray-600 font-medium text-sm mb-2">
            Paste a URL from anywhere online:
          </label>
          <input
            type="url"
            className="w-full border border-gray-300 rounded-full p-2 bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm h-10"
            placeholder="https://"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            disabled={loading}
          />
        </div>
      </div>

      {/* Submit Button or Loading Animation */}
      {!loading ? (
        <button
          type="submit"
          className="w-full py-2 text-white bg-gradient-to-r from-purple-500 to-blue-500 rounded-full hover:from-blue-600 hover:to-purple-600 transition-all duration-300 text-sm"
        >
          Next
        </button>
      ) : (
        <div className="flex flex-col justify-center items-center mt-4 space-y-2">
          <FaGift className="animate-bounce text-purple-500 w-8 h-8" />
          <span className="text-purple-600 text-sm font-medium">
            Getting your gift...
          </span>
        </div>
      )}

      {/* Manual Add Link Below the Button */}
      <div className="flex justify-center mt-4">
        <button
          type="button"
          onClick={onAddItem}
          className="text-blue-600 text-sm underline hover:text-blue-800 focus:outline-none"
        >
          Don’t have a link? Add manually
        </button>
      </div>
    </form>
  );
};

export default AddWishlistItemForm;
