// src/components/SkeletonSearchTile.tsx
import React from "react";

const SkeletonSearchTile: React.FC = () => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-4 transition-all hover:shadow-2xl cursor-pointer flex items-center space-x-4 animate-pulse">
      {/* Skeleton for profile picture */}
      <div className="flex-shrink-0">
        <div className="w-14 h-14 bg-gray-300 rounded-full" />
      </div>

      {/* Skeleton for profile content */}
      <div className="flex-grow">
        {/* Skeleton for name */}
        <div className="h-4 bg-gray-300 rounded w-1/3 mb-2"></div>

        {/* Skeleton for social media icons */}
        <div className="flex space-x-4">
          <div className="h-4 bg-gray-300 rounded w-1/4"></div>
          <div className="h-4 bg-gray-300 rounded w-1/4"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonSearchTile;
