import React from "react";
import { BsGift } from "react-icons/bs";

interface GiftPlaceholderProps {
  size?: number; // Size in pixels
  color?: string; // Color of the icon
}

const GiftPlaceholder: React.FC<GiftPlaceholderProps> = ({
  size = 24, // Default size
  color = "text-purple-400", // Default color
}) => {
  return (
    <BsGift className={`${color}`} style={{ width: size, height: size }} />
  );
};

export default GiftPlaceholder;
