import React from "react";
import {
  FaInstagram,
  FaTiktok,
  FaEdit,
  FaGift,
  FaTelegram,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface ProfileHeaderProps {
  onEditSocialLinks: () => void;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ onEditSocialLinks }) => {
  const profileState = useSelector((state: RootState) => state.profile);
  const profile = profileState.data;
  const loggedInUserId = useSelector(
    (state: RootState) => state.auth.user?._id
  );

  if (!profile) {
    return <div>Loading profile...</div>;
  }

  const isOwnProfile = profile.ownerId === loggedInUserId;
  const profilePicture =
    profile.profilePicture || "https://via.placeholder.com/150";
  const { name = "Anonymous", socialLinks = {} } = profile;
  const { instagram, tiktok, telegram } = socialLinks;

  const instagramUrl = instagram
    ? `https://www.instagram.com/${instagram}/`
    : "";
  const tiktokUrl = tiktok ? `https://www.tiktok.com/@${tiktok}` : "";
  const telegramUrl = telegram ? `https://t.me/${telegram}` : "";

  return (
    <div className="bg-white pt-2 md:pt-8 pb-0 px-6 lg:px-20 rounded-t-lg shadow-md max-w-5xl mx-auto border-l-2 border-r-2 border-t-2 border-purple-500">
      <div className="flex flex-col items-center">
        <div className="text-center mt-10">
          <h1 className="text-2xl sm:text-3xl font-semibold text-gray-800 drop-shadow-sm">
            {name}
          </h1>
        </div>

        {/* Social Links Row */}
        <div className="flex items-center mt-5 space-x-4 divide-x divide-gray-300">
          {instagram && (
            <a
              href={instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-1 text-gray-700 hover:text-pink-700 transition-colors text-xs sm:text-sm"
            >
              <FaInstagram className="text-pink-500 text-lg sm:text-xl" />
              <span className="text-xs sm:text-sm">@{instagram}</span>
            </a>
          )}
          {tiktok && (
            <a
              href={tiktokUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-1 text-gray-700 hover:text-gray-900 transition-colors pl-4 text-xs sm:text-sm"
            >
              <FaTiktok className="text-black text-lg sm:text-xl" />
              <span className="text-xs sm:text-sm">@{tiktok}</span>
            </a>
          )}
          {telegram && (
            <a
              href={telegramUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-1 text-gray-700 hover:text-blue-600 transition-colors pl-4 text-xs sm:text-sm"
            >
              <FaTelegram className="text-[#0088cc] text-lg sm:text-xl" />
              <span className="text-xs sm:text-sm">@{telegram}</span>
            </a>
          )}
        </div>

        {/* Edit Button Row */}
        {isOwnProfile && (
          <div className="mt-3">
            <button
              onClick={onEditSocialLinks}
              className="flex items-center space-x-1 text-blue-600 bg-blue-50 hover:bg-blue-100 rounded-full py-1 px-3 shadow-sm transition-all duration-150 text-xs sm:text-sm"
              title="Edit Social Links"
            >
              <FaEdit className="text-blue-600 text-lg sm:text-xl" />
              <span className="text-xs sm:text-sm">Edit</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;
