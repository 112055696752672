// src/redux/store.ts
import { configureStore } from '@reduxjs/toolkit';
import searchReducer from '../features/search/redux/searchSlice'; // Use the correct import path
import profileReducer from '../features/profile/redux/profileSlice';
import wishlistReducer from '../features/wishlist/redux/wishlistSlice';
import authReducer from '../features/auth/redux/authSlice';
import uiReducer  from './uiSlice';

const store = configureStore({
  reducer: {
    search: searchReducer, // Add search slice here
    profile: profileReducer, // Add this line
    wishlist: wishlistReducer, // Add this line
    auth: authReducer,
    ui: uiReducer,


    // Add other slices as needed
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
