import React, { useState } from "react";
import { FaSearch, FaGift } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { searchForFriendsWithFilters } from "../redux/searchSlice"; // Redux action
import { AppDispatch } from "../../../redux/store";

const SearchBar: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await dispatch(
        searchForFriendsWithFilters({
          query: searchQuery,
          sortBy: "",
          platforms: [],
        })
      );
      setTimeout(() => {
        navigate(`/search/results?query=${searchQuery}`);
      }, 500);
    } catch (error) {
      console.error("Error during search:", error);
    } finally {
      setTimeout(() => setLoading(false), 300);
    }
  };

  return (
    <form
      onSubmit={handleSearch}
      className="w-full max-w-xl sm:max-w-2xl lg:max-w-4xl mx-auto px-2"
    >
      <div className="flex items-center bg-white rounded-full shadow-lg p-1 sm:p-2 hover:shadow-xl transition-all duration-300 border-2 border-purple-200">
        <FaSearch className="text-purple-400 ml-4 sm:ml-2" />
        <input
          type="text"
          placeholder="Search by name or social media handle..."
          className="flex-grow p-2 sm:p-3 rounded-l-full focus:outline-none text-gray-800 ml-2 text-sm sm:text-base"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button
          type="submit"
          className={`bg-pink-500 text-white rounded-full p-3 hover:bg-pink-600 transition-transform transform hover:scale-105 duration-300 flex items-center ${
            loading ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={loading}
          aria-label="Search"
        >
          {loading ? (
            <FaGift className="animate-spin text-lg" />
          ) : (
            <FaGift className="text-lg" />
          )}
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
