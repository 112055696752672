import { useSelector } from "react-redux";
import { RootState } from "../redux/store"; // Adjust the path accordingly

// Custom hook to check if the user is authenticated and verified
const useAuth = () => {
  // Access the authentication state from Redux, including isVerified
  const { isAuthenticated, user, isVerified } = useSelector((state: RootState) => state.auth);

  return { isAuthenticated, user, isVerified };
};

export default useAuth;
