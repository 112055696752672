import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WishlistItem } from '../../../redux/types';

interface WishlistState {
  wishlistItems: WishlistItem[];
  activeList: string;  // Ensure the active list state exists
  fetchedItem: WishlistItem | null;
}

const initialState: WishlistState = {
  wishlistItems: [],
  activeList: 'all',  // Default active list
  fetchedItem: null,
};
const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setWishlistItems: (state, action: PayloadAction<WishlistItem[]>) => {
      state.wishlistItems = action.payload;
    },
    toggleReservation: (state, action: PayloadAction<{ itemId: string; userId: string | null }>) => {
      const { itemId, userId } = action.payload;
      const item = state.wishlistItems.find((i) => i._id === itemId);
      if (item) {
        item.reservedBy = userId;  // Update reservedBy directly
      }
    },
    addWishlistItem: (state, action: PayloadAction<WishlistItem>) => {
      state.wishlistItems.push(action.payload);
    },
    deleteWishlistItem: (state, action: PayloadAction<string>) => {
      state.wishlistItems = state.wishlistItems.filter(
        (item) => item._id !== action.payload
      );
    },
    setActiveList: (state, action: PayloadAction<string>) => {
      state.activeList = action.payload;
    },
    setFetchedItem: (state, action: PayloadAction<WishlistItem>) => {
      state.fetchedItem = action.payload;
    },
    clearFetchedItem: (state) => {
      state.fetchedItem = null;
    },
  },
});

export const {
  setWishlistItems,
  toggleReservation, // Add the toggleReservation action
  addWishlistItem,
  deleteWishlistItem,
  setActiveList,
  setFetchedItem,
  clearFetchedItem,
} = wishlistSlice.actions;

export default wishlistSlice.reducer;