import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./components/Layout";
import ProfilePage from "./features/profile/components/ProfilePage";
import FriendSearchPage from "./features/search/components/FriendSearchPage";
import SearchResultsPage from "./features/search/components/SearchResultsPage";
import LoginPage from "./features/auth/components/LoginPage";
import RegistrationPage from "./features/auth/components/RegistrationPage";
import OtpVerificationPage from "./features/auth/components/OtpVerificationPage";
import ProtectedRoute from "./features/auth/components/ProtectedRoute";
import {
  restoreSessionFromCookie,
  verifyAuth,
} from "./features/auth/services/authActions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import useAuth from "./hooks/useAuth";
import LoadingModal from "./components/LoadingModal";
import OAuthCallbackPage from "./features/auth/components/OAuthCallbackPage";
import { clearError } from "./features/auth/redux/authSlice";

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuthenticated, user } = useAuth();
  const { isLoading, loadingMessage } = useSelector(
    (state: RootState) => state.ui
  );
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    dispatch(clearError());

    const restoreSession = async () => {
      dispatch(restoreSessionFromCookie()); // Try to restore session from cookie
      setAuthLoading(false); // Set authLoading to false after checking cookie
    };
    restoreSession();
  }, [dispatch]);

  // Show loading while verifying auth state
  if (authLoading)
    return <LoadingModal isOpen={true} message="Checking on your gifts..." />;

  return (
    <Router>
      {/* LoadingModal is rendered here to ensure it remains outside of Layout */}
      {isLoading && (
        <LoadingModal isOpen={isLoading} message={loadingMessage} />
      )}

      <Layout>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<FriendSearchPage />} />
          <Route path="/search/results" element={<SearchResultsPage />} />
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/" /> : <LoginPage />}
          />
          <Route
            path="/register"
            element={
              isAuthenticated ? <Navigate to="/" /> : <RegistrationPage />
            }
          />

          {/* Protected Routes */}
          <Route path="/verify-otp" element={<OtpVerificationPage />} />

          {/* Protected Route for Own Profile */}
          <Route
            path="/profile/:userId"
            element={
              <ProtectedRoute userId={user?._id}>
                <ProfilePage />
              </ProtectedRoute>
            }
          />

          {/* Use ProtectedRoute for other private routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/search" element={<FriendSearchPage />} />
          </Route>
          <Route path="/oauth-callback" element={<OAuthCallbackPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
