import React, { useState } from "react";
import { FaUser, FaLock, FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
import SocialLoginButtons from "./SocialLoginButtons";
import { register } from "../services/authActions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { validatePassword } from "../../../services/validation";
import { loginFailure } from "../redux/authSlice";

const RegistrationPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const error = useSelector((state: RootState) => state.auth.error); // Pull error from Redux

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      dispatch(loginFailure("Passwords do not match")); // Dispatch to set error in Redux
      return;
    }

    const passwordError = validatePassword(password);
    if (passwordError) {
      dispatch(loginFailure(passwordError)); // Dispatch to set error in Redux
      return;
    }

    try {
      await dispatch(register(email, password, name) as any);
      navigate("/verify-otp");
    } catch (err) {
      console.error("Registration failed:", err);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-auth px-4 pb-36 md:pb-20 lg:pb-0">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full space-y-6 transform transition-all duration-500">
        <h2 className="text-2xl font-bold text-gray-800 text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-600">
          Create an Account
        </h2>

        {/* {error && <div className="text-red-500 text-center mb-4">{error}</div>} */}

        {error &&
          error !== "Authentication failed" &&
          error !== "No token found in cookies" && (
            <p className="text-red-500 text-center">{error}</p>
          )}

        <form onSubmit={handleSubmit} className="space-y-4 px-4 md:px-8 py-4">
          {/* Input fields and form elements */}
          <div className="relative border border-transparent bg-gradient-to-r from-purple-400 to-blue-400 rounded-full p-0.5">
            <div className="relative rounded-full bg-white">
              <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full py-3 pl-10 pr-4 bg-transparent border-none rounded-full focus:outline-none"
                required
              />
            </div>
          </div>

          <div className="relative border border-transparent bg-gradient-to-r from-purple-400 to-blue-400 rounded-full p-0.5">
            <div className="relative rounded-full bg-white">
              <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full py-3 pl-10 pr-4 bg-transparent border-none rounded-full focus:outline-none"
                required
              />
            </div>
          </div>

          {/* Password Input with Show/Hide Toggle */}
          <div className="relative border border-transparent bg-gradient-to-r from-purple-400 to-blue-400 rounded-full p-0.5">
            <div className="relative rounded-full bg-white">
              <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full py-3 pl-10 pr-12 bg-transparent border-none rounded-full focus:outline-none"
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pr-1"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>

          {/* Confirm Password Input with Show/Hide Toggle */}
          <div className="relative border border-transparent bg-gradient-to-r from-purple-400 to-blue-400 rounded-full p-0.5">
            <div className="relative rounded-full bg-white">
              <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full py-3 pl-10 pr-12 bg-transparent border-none rounded-full focus:outline-none"
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pr-1"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="w-full py-3 text-white bg-gradient-to-r from-purple-500 to-blue-500 rounded-full hover:from-blue-600 hover:to-purple-600 transition-all duration-300"
          >
            Sign Up
          </button>
        </form>

        <div className="flex items-center my-6">
          <div className="flex-grow h-px bg-gray-300"></div>
          <span className="px-4 text-gray-500 text-sm">OR</span>
          <div className="flex-grow h-px bg-gray-300"></div>
        </div>

        <SocialLoginButtons
          onFacebookLogin={() => console.log("Facebook sign-up")}
        />

        <div className="text-center mt-6">
          <p className="text-sm text-gray-600">
            Already have an account?{" "}
            <Link to="/login" className="text-blue-500 hover:underline">
              Log in here
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
