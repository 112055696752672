import axios from '../../../services/axiosConfig'; // Your Axios instance
import { AppDispatch, RootState } from '../../../redux/store'; // Import AppDispatch from your store
import { profileFailure, profileStart, profileSuccess } from '../redux/profileSlice';
import { setWishlistItems } from '../../wishlist/redux/wishlistSlice';
export const fetchProfileById = (userId: string) => async (dispatch: AppDispatch) => {
  dispatch(profileStart());
  try {
    const response = await axios.get(`/profile/${userId}`);
    //console.log("Fetched profile data:", response.data); // Log fetched data
    dispatch(profileSuccess(response.data)); // Ensure response includes ownerId and wishlist

      dispatch(setWishlistItems(response.data.wishlist));

  } catch (error) {
    if (error instanceof Error) {
      dispatch(profileFailure(error.message));
    } else {
      dispatch(profileFailure('An unknown error occurred'));
    }
  }
};

interface SocialLinksPayload {
  instagram?: string;
  tiktok?: string;
  telegram?: string;
}

export const updateProfileSocialLinks = (socialLinks: SocialLinksPayload) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  try {
    const state = getState();
    const userId = state.auth.user?._id;

    if (!userId) {
      throw new Error('User not authenticated');
    }

    // Update the profile on the backend
    const response = await axios.put(`/profile/${userId}/social-links`, socialLinks);

    // Dispatch the updated profile data
    dispatch(profileSuccess(response.data));
  } catch (error) {
    console.error('Error updating profile social links:', error);
    dispatch(profileFailure('Failed to update profile'));
  }
};