import React from "react";
import { FaTimes } from "react-icons/fa";
import GiftPlaceholder from "./GiftPlaceholder";
import { WishlistItem } from "../../../redux/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useOwnership } from "../../../hooks/useOwnership";

interface WishlistTileProps {
  item: WishlistItem;
  onItemSelect: (item: WishlistItem) => void;
  onEditItem: (item: WishlistItem) => void;
  onDeleteItem: (itemId: string) => void;
}

const WishlistTile: React.FC<WishlistTileProps> = ({
  item,
  onItemSelect,
  onEditItem,
  onDeleteItem,
}) => {
  const ownerId = useSelector(
    (state: RootState) => state.profile.data?.ownerId
  );
  const isOwner = useOwnership(ownerId);

  const handleClick = () => {
    if (isOwner) {
      onEditItem(item);
    } else {
      onItemSelect(item);
    }
  };

  return (
    <div
      className={`relative w-full max-w-sm mx-auto p-3 bg-white border border-purple-400 rounded-lg shadow-sm transition-all duration-300 cursor-pointer
        hover:bg-purple-50 hover:shadow-md ${isOwner ? "hover:shadow-lg" : ""}`}
      onClick={handleClick}
    >
      {isOwner && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDeleteItem(item._id);
          }}
          className="absolute top-1 right-1 bg-gray-200 text-gray-600 hover:text-red-500 hover:bg-red-100 rounded-full p-1 transition-colors"
        >
          <FaTimes className="w-3 h-3" />
        </button>
      )}

      <div className="flex items-center space-x-2 sm:space-x-4">
        <GiftPlaceholder size={24} color="text-purple-400" />

        <div className="flex-grow pl-2 overflow-hidden">
          <h3
            className=" font-semibold text-gray-800 truncate max-w-[14rem] sm:max-w-[18rem]"
            title={item.title}
          >
            {item.title || "Unnamed Item"}
          </h3>
          <p className="text-xs sm:text-sm text-gray-500 mt-1">
            {item.price
              ? `${item.currency}${item.price}`
              : "No price available"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WishlistTile;
