// src/services/searchActions.ts

import api from '../../../services/axiosConfig'; // Adjust the path as needed

// API function for searching friends with filters
export const searchFriendsWithFilters = async ({
  query,
  sortBy,
  platforms,
}: {
  query?: string;
  sortBy?: string;
  platforms?: string[];
}) => {
  try {
    const params: any = {};
    if (query) params.query = query;
    if (sortBy) params.sortBy = sortBy;
    if (platforms && platforms.length > 0) params.platforms = platforms;

    const response = await api.get(`/profile/search`, {
      params,
      // Use the default paramsSerializer of Axios or adjust accordingly
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || 'Something went wrong');
  }
};
