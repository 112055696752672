import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";

interface AddWishlistModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: React.ReactNode; // Optional title prop to customize modal header
}

const AddWishlistModal: React.FC<AddWishlistModalProps> = ({
  isOpen,
  onClose,
  children,
  title = "Add New Item", // Default title if not provided
}) => {
  // Close the modal when 'Esc' key is pressed
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEsc);
    } else {
      document.removeEventListener("keydown", handleEsc);
    }

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-300 ease-out"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      tabIndex={-1}
    >
      <div className="relative bg-white rounded-xl shadow-2xl w-full max-w-lg max-h-screen overflow-auto p-6">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none transition-colors"
          aria-label="Close Modal"
        >
          <FaTimes className="text-xl" />
        </button>

        {/* Modal Title */}
        <h2
          id="modal-title"
          className="text-xl font-semibold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600"
        >
          {title}
        </h2>

        {/* Scrollable content */}
        <div className="space-y-6">{children}</div>
      </div>
    </div>
  );
};

export default AddWishlistModal;
