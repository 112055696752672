import React, { useState } from "react";
import { FaShoppingCart, FaStore, FaTimes } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { toggleReserveItem } from "../services/wishlistActions";
import { WishlistItem } from "../../../redux/types";
import GiftPlaceholder from "./GiftPlaceholder";

interface WishlistItemModalProps {
  item: WishlistItem;
  onClose: () => void;
  ownerId: string;
}

const WishlistItemModal: React.FC<WishlistItemModalProps> = ({
  item,
  onClose,
  ownerId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const currentUserId = useSelector((state: RootState) => state.auth.user?._id);

  const itemFromStore =
    useSelector((state: RootState) =>
      state.wishlist.wishlistItems.find((i) => i._id === item._id)
    ) || item;

  const reservedBy = itemFromStore.reservedBy;
  const isReserved = reservedBy === currentUserId;
  const isReservedByAnotherUser = reservedBy && reservedBy !== currentUserId;

  const [optimisticReserved, setOptimisticReserved] = useState(isReserved);

  const toggleReserve = async () => {
    setOptimisticReserved(!optimisticReserved);

    try {
      await dispatch(toggleReserveItem(itemFromStore._id));
    } catch (error) {
      console.error("Failed to toggle reservation:", error);
      setOptimisticReserved(isReserved);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
      <div className="relative bg-white rounded-xl shadow-lg w-full max-w-md p-6">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          aria-label="Close modal"
        >
          <FaTimes className="text-xl" />
        </button>

        {/* Modal Content */}
        <div className="text-center">
          <h2 className="text-xl font-bold text-gray-900 mb-4 font-heading">
            {itemFromStore.title}
          </h2>

          {/* Image or Gift Placeholder */}
          <div className="flex justify-center mb-4">
            {itemFromStore.selectedImage ? (
              <img
                src={itemFromStore.selectedImage}
                alt={itemFromStore.title}
                className="w-full max-h-48 object-cover rounded-lg"
              />
            ) : (
              <div className="flex items-center justify-center w-full h-48 bg-gray-100 rounded-lg">
                <GiftPlaceholder size={48} color="text-purple-400" />
              </div>
            )}
          </div>

          {/* Price */}
          <p className="text-lg font-semibold text-purple-700 mb-2">
            {itemFromStore.price
              ? `${itemFromStore.currency}${itemFromStore.price}`
              : "Price not available"}
          </p>

          {/* Description */}
          {itemFromStore.description && (
            <div className="text-left mb-4">
              <h3 className="text-gray-800 font-semibold">Notes</h3>
              <p className="text-gray-600 text-sm bg-gray-50 p-3 rounded-md ">
                {itemFromStore.description}
              </p>
            </div>
          )}

          {/* Reserve Toggle */}
          <div className="flex items-center justify-center mb-6 space-x-3">
            {!isReservedByAnotherUser ? (
              <>
                <span className="text-gray-700 font-medium">
                  {optimisticReserved
                    ? "Reserved by You!"
                    : "Reserve this Gift"}
                </span>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={optimisticReserved}
                    onChange={toggleReserve}
                    className="sr-only peer"
                  />
                  <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </>
            ) : (
              <p className="text-red-500">Reserved by another user</p>
            )}
          </div>

          {/* Give This Gift Button */}
          {itemFromStore.link && (
            <a
              href={itemFromStore.link}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center px-4 py-2 mt-4 bg-gradient-to-r from-purple-500 to-blue-500 text-white font-semibold rounded-full shadow-lg hover:from-purple-600 hover:to-blue-600 transition"
            >
              <FaStore className="mr-2" />
              <span>Give this Gift</span>
              <FaShoppingCart className="ml-2" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default WishlistItemModal;
