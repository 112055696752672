// SocialLoginButtons.tsx
import React from "react";
import { googleLogin } from "../services/authActions"; // Import google login action

interface SocialLoginButtonsProps {
  onFacebookLogin: () => void;
}

const SocialLoginButtons: React.FC<SocialLoginButtonsProps> = ({
  onFacebookLogin,
}) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      {/* Google Login Button */}
      <button
        className="col-span-1 py-3 border border-neutral-300 rounded-lg flex items-center justify-center shadow-sm cursor-pointer hover:bg-neutral-100 transition-all duration-300"
        onClick={googleLogin} // Directly using googleLogin action
      >
        <img
          className="w-5 h-5 flex-shrink-0 ml-1.5 mr-2"
          src="/assets/images/google-DJOPPODp.png"
          alt="Google logo"
        />
        <span className="font-body text-sm">Google</span>
      </button>

      {/* Facebook Login Button */}
      <button
        className="col-span-1 py-3 border border-neutral-300 rounded-lg flex items-center justify-center shadow-sm cursor-pointer hover:bg-neutral-100 transition-all duration-300"
        onClick={onFacebookLogin} // Passing Facebook login handler as a prop
      >
        <img
          className="w-5 h-5 flex-shrink-0 ml-1.5 mr-2"
          src="/assets/images/facebook-BZE0LojR.png"
          alt="Facebook logo"
        />
        <span className="font-body text-sm">Facebook</span>
      </button>
    </div>
  );
};

export default SocialLoginButtons;
