import React from "react";
import { FaInstagram, FaTiktok, FaTelegramPlane } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface SearchTileProps {
  user_id: string;
  name: string;
  profilePicture: string;
  socialLinks?: {
    instagram?: string;
    tiktok?: string;
    telegram?: string;
  };
}

const SearchTile: React.FC<SearchTileProps> = ({
  user_id,
  name,
  profilePicture,
  socialLinks = {},
}) => {
  const navigate = useNavigate();

  const handleTileClick = () => {
    navigate(`/profile/${user_id}`);
  };

  const instagramUrl = socialLinks.instagram
    ? `https://www.instagram.com/${socialLinks.instagram}/`
    : undefined;
  const tiktokUrl = socialLinks.tiktok
    ? `https://www.tiktok.com/@${socialLinks.tiktok}`
    : undefined;
  const telegramUrl = socialLinks.telegram
    ? `https://t.me/${socialLinks.telegram}`
    : undefined;

  return (
    <div
      onClick={handleTileClick}
      className="bg-white rounded-lg shadow-lg py-4 px-6 transition-all hover:bg-purple-50 hover:shadow-xl hover:scale-105 cursor-pointer"
    >
      {/* Profile Picture Section */}
      {/*
      <div className="flex-shrink-0">
        <img
          src={profilePicture}
          alt={`${name}'s profile`}
          className="w-12 h-12 rounded-full object-cover"
        />
      </div>
      */}

      {/* Content Section */}
      <div className="overflow-hidden">
        <h2 className="text-lg font-bold text-gray-800 truncate">{name}</h2>
        <div className="flex space-x-4 mt-2">
          {socialLinks.instagram && (
            <div className="text-gray-600 flex items-center space-x-1">
              <FaInstagram className="text-pink-500" />
              <span className="text-sm truncate">@{socialLinks.instagram}</span>
            </div>
          )}
          {socialLinks.tiktok && (
            <div className="text-gray-600 flex items-center space-x-1">
              <FaTiktok className="text-black" />
              <span className="text-sm truncate">@{socialLinks.tiktok}</span>
            </div>
          )}
          {socialLinks.telegram && (
            <div className="text-gray-600 flex items-center space-x-1">
              <FaTelegramPlane className="text-blue-500" />
              <span className="text-sm truncate">@{socialLinks.telegram}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchTile;
