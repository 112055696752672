import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AddWishlistModal from "./AddWishlistModal";
import AddWishlistItemForm from "./AddWishlistItemForm";
import WishlistItemForm from "./WishlistItemForm";
import { addNewWishlistItem } from "../services/wishlistActions";
import { AppDispatch } from "../../../redux/store";
import { WishlistItem } from "../../../redux/types";

interface AddWishlistFlowProps {
  userId: string;
  onClose: () => void;
}

const AddWishlistFlow: React.FC<AddWishlistFlowProps> = ({
  userId,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [step, setStep] = useState(1);

  const handleFirstSubmit = () => {
    setStep(2);
  };

  const handleSaveItem = async (itemData: WishlistItem): Promise<void> => {
    dispatch(addNewWishlistItem(itemData));
    onClose();
  };

  return (
    <AddWishlistModal
      isOpen={step > 0}
      onClose={onClose}
      title={<div className="text-center">Add Item</div>}
    >
      {step === 1 ? (
        <AddWishlistItemForm onAddItem={handleFirstSubmit} />
      ) : (
        <WishlistItemForm
          onClose={onClose}
          onSave={handleSaveItem}
          isEditing={false}
        />
      )}
    </AddWishlistModal>
  );
};

export default AddWishlistFlow;
