import React from "react";
import SearchBar from "./SearchBar";
import SloganSection from "./SloganSection";

const FriendSearchPage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-search px-4 pb-36 lg:pb-20">
      {/* Slogan Section */}
      <div className="mb-6 sm:mb-8">
        <SloganSection />
      </div>

      {/* Search Bar */}
      <div className="w-full max-w-md sm:max-w-xl md:max-w-3xl lg:max-w-3xl">
        <SearchBar />
      </div>
    </div>
  );
};

export default FriendSearchPage;
